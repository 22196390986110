import { configureStore } from '@reduxjs/toolkit'
import authReducer from "../slicer/Auth"
import productReducer from "../slicer/Products"
import categoryReducer from "../slicer/Category"
import userReducer from "../slicer/UserSlice"

export const store = configureStore({
  reducer: {
    auth:authReducer,
    categories: categoryReducer,
    products: productReducer,
    users: userReducer,
  },
})