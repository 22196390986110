import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import requestHandler from "./requestHandler";
import { thunkError, getToken } from "./requestHandler";



const initialState = {
  products: [],
  status: "idle",
  message: "",
};

export const createProducts = createAsyncThunk(
  "products/create",
  async (credentials, ThunkAPI) => {
    try {
      const token = getToken(ThunkAPI)
      const res = await requestHandler.axioPostHeader(
        "products",
        credentials,
        token
      );
      return res?.data;
    } catch (error) {
      return ThunkAPI.rejectWithValue(thunkError(error));
    }
  }
);

export const getProducts = createAsyncThunk(
  "products/get_all",
  async (_, ThunkAPI) => {
    try {
      const res = await requestHandler.axioGet("products");
      return res?.data;
    } catch (error) {
      return ThunkAPI.rejectWithValue(thunkError(error));
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/update",
  async (credentials, ThunkAPI) => {
    try {
      const token = getToken(ThunkAPI)
      const { _id, ...rest } = credentials;
      const res = await requestHandler.axioPatchHeader(
        `products/${_id}`,
        rest,
        token
      );
      return res?.data;
    } catch (error) {
      return ThunkAPI.rejectWithValue(thunkError(error));
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "products/delete",
  async (credentials, ThunkAPI) => {
    try {
      const token = getToken(ThunkAPI)
      const res = await requestHandler.axioDeleteHeader(
        `products/${credentials._id}`,
        token
      );
      return res?.data;
    } catch (error) {
      return ThunkAPI.rejectWithValue(thunkError(error));
    }
  }
);

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    reseter: (state) => {
      state.status = "idle";
      state.message = "";
    },
  },
  extraReducers: (builder) =>{
    //  product creations 
    builder.addCase(createProducts.pending, (state, _)=>{
      state.status = "loading"; 
    });
    builder.addCase(createProducts.fulfilled, (state, { payload })=>{
      state.status = "succeeded";
      state.products.push(payload?.data);
      state.message = payload?.message
    });
    builder.addCase(createProducts.rejected, (state, { payload })=>{
      state.status = "failed";
      state.message = payload;
      state.message = payload?.message
    });
    // get section 
    builder.addCase(getProducts.pending, (state, _)=>{
      state.status = "loading"; 
    });
    builder.addCase(getProducts.fulfilled, (state, { payload })=>{
      state.status = "succeeded";
      state.status = "idle";
      state.products = payload?.data;
    });
    builder.addCase(getProducts.rejected, (state, { payload })=>{
      state.status = "failed";
      state.message = payload.data;
      state.status = "idle";
    });
    // delete section 
    builder.addCase(deleteProduct.pending, (state, _)=>{
      state.status = "loading"; 
    });
    builder.addCase(deleteProduct.fulfilled, (state, { payload })=>{
      state.status = "succeeded";
      const { _id } = payload.data;
      state.products = state.products.filter((p) => p._id !== _id);
    });
    builder.addCase(deleteProduct.rejected, (state, { payload })=>{
      state.status = "failed";
      state.message = payload;
      state.status = "idle";
    });

       // update product status 
       builder.addCase(updateProduct.pending, (state, { payload })=>{
        state.status = "loading"; 
      });
      builder.addCase(updateProduct.fulfilled, (state, { payload })=>{
        state.status = "succeeded";
        const { _id } = payload?.data;
        state.products = state.products.map((per) =>
          per._id === _id ? payload : per
        );
        state.message = payload?.message
      });
      builder.addCase(updateProduct.rejected, (state, { payload })=>{
        state.status = "failed";
        state.message = payload.message;
        state.status = "idle";
      });
  }
});

const { reducer, actions } = productSlice;
export const selectAllProducts = (state) => state?.products?.products;
export const getProductStatus = (state) => state?.products?.status;
export const getProductError = (state) => state?.products?.message;
export const getProductById = (state, id) =>
  state.products.products.find((pro) => pro._id === id);

export const { reseter } = actions;
export default reducer;