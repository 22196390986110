import React from 'react'
import BreadCumb from '../components/BreadCumb';
import Layer from '../components/Layer';
import Products from '../components/Products';
import Reality from '../components/Reality';
import Play from '../components/Play';
import PlayStation from '../components/PlayStation';

const Home = () => {
    return (
        <div>
            <BreadCumb />
            <PlayStation/>
            <Products />
            <Reality/>
            <Layer />
            <Play/>
        </div>
    )
}

export default Home
