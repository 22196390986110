import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getProducts, selectAllProducts, reseter } from "../slicer/Products";
import { getUser } from "../slicer/Auth"
import { Notifier } from "../utills/InputHelpers"

const ProductList = () => {
    const { status, message } = useSelector((state) => state?.products);
    const referal = useRef();
    const tbStyle = {
        overflowX: 'auto',
    };

    const { user } = useSelector(getUser);
    console.log(user)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getProducts())
    }, [dispatch]);
    const datas = useSelector(selectAllProducts);
    const handleDelete = (id) => {
        // dispatch(updateBookingStatus({ _id: id }));
        if (status === "succeeded") {
            Notifier("item updated", "success")
            dispatch(reseter());
        }
        if (status === "failed") {
            // toast.error(message, { autoClose: 4000 });
            Notifier(message, "error")
            dispatch(reseter());
        }
    }

    referal.current = handleDelete;
    return (
        <div style={tbStyle} className='container-fluid mt-5 mb-5 p-5'>
            <h3 className='text-center bg-custom text-white p-3 text-styled'> Product</h3>

            <table className='table striped bordered hover table-responsive'>
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th> Brand</th>
                        <th> Price</th>
                        <th> Quantity</th>

                        <th> State</th>
                        <th>Category Info</th>
                        <th>Action</th>

                    </tr>
                </thead>
                <tbody>
                    {datas.map(dt => (
                        <tr key={dt._id}>
                            <td>
                                {dt?.images && (
                                    <img src={dt?.images} alt={dt._id} width={100} height={70} />
                                )}

                            </td>
                            <td>
                                {dt?.name} <br />
                            </td>
                            <td>
                                {dt?.desc} <br />

                            </td>
                            <td>
                                {dt?.brand}
                            </td>
                            <td>
                                {dt?.price}
                            </td>
                            <td>
                                {dt?.quantity}
                            </td>

                            <td>
                                {dt?.state}
                            </td>

                            <td>
                                name: {dt.catId?.name} <br />
                                name: {dt.catId?.desc} <br />
                            </td>
                            <td>
                                {user?.user?.username === process.env.REACT_APP_ROLER ? (
                                    <>
                                        <div className='my-3'>

                                            <a className="btn btn-outline-success" style={{ textDecoration: "none" }} href={`/products-update/${dt?._id}`}>
                                                {/* <button className="btn btn-outline-success" onClick={() => handleDelete(dt._id)}> edit</button> */}
                                                edit
                                            </a>
                                        </div>

                                    </>
                                ) : dt?.status}

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProductList;
