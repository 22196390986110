import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getCategory, selectAllCategories, deleteCat, reseter } from "../slicer/Category";
import { getUser } from "../slicer/Auth"
import { Notifier } from "../utills/InputHelpers"

const CategoryList = () => {
    const { status, message } = useSelector((state) => state?.products);
    const referal = useRef();
    const tbStyle = {
        overflowX: 'auto',
    };

    const { user } = useSelector(getUser);
    console.log(user)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCategory())
    }, [dispatch]);
    const datas = useSelector(selectAllCategories);
    const handleDelete = (id) => {

        dispatch(deleteCat({ _id: id }));


        if (status === "succeeded") {
            Notifier("item updated", "success")
            dispatch(reseter());
        }
        if (status === "failed") {
            Notifier(message, "error")
            dispatch(reseter());
        }
    }

    referal.current = handleDelete;
    return (
        <div style={tbStyle} className='container-fluid mt-5 mb-5 p-5'>

            <h3 className='text-center bg-custom text-white p-3 text-styled'> Category</h3>

            <table className='table striped bordered hover table-responsive'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Action</th>

                    </tr>
                </thead>
                <tbody>
                    {datas.map(dt => (
                        <tr key={dt._id}>
                            <td>
                                {dt?._id}

                            </td>
                            <td>
                                {dt?.name} <br />
                            </td>
                            <td>
                                {dt?.desc} <br />

                            </td>
                       
                            <td>
                                {user?.user?.username === process.env.REACT_APP_ROLER ? (
                                    <>
                                        <div className='my-3'>
                                         
                                            <button className="btn btn-outline-success" onClick={() => handleDelete(dt._id)}> delete</button>
                                           
                                        </div>

                                    </>
                                ) : dt?.status}

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CategoryList;
