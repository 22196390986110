import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import upload from "../utills/cloudinaryUpload"
import { useNavigate, useParams } from "react-router-dom";
import { handleInput, validateEmpty, Notifier } from "../utills/InputHelpers";
import { getProducts, updateProduct, selectAllProducts, reseter } from "../slicer/Products";
import { selectAllCategories } from "../slicer/Category";


const ProductUpdate = () => {
    // const products = useSelector((state) => state.products);
    const { status, message } = useSelector((state) => state?.products);
    const products = useSelector(selectAllProducts);
    const navigate = useNavigate();
    const { productId } = useParams();
    const targetProduct = products.filter((pro) => pro._id === productId)[0];

    console.log(targetProduct);
    
    const [formData, setFormData] = useState({
        name: '',
        price: 0,
        brand: '',
        quantity: 0,
        color: '',
        catId: '',
        desc: '',
        images: '',
        _id: '',
    });

    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [singleFile, setSingleFile] = useState(undefined);
    const [uploading, setUploading] = useState(false);
    const dispatch = useDispatch();
    const referal = useRef();


    //get catgories
    const categories = useSelector(selectAllCategories);
    const categoriesOption = !categories
        ? ""
        : Array.from(categories)
            .sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            })
            .map((category) => {
                return (

                    <option key={category._id} value={category._id}>
                        {category.name}
                    </option>
                );
            });

    useEffect(() => {
        dispatch(getProducts());
        if (!productId) {
            navigate("/productlist");
        }
    }, [productId, navigate, dispatch, formData]);


    const handleUpload = async (e) => {
        e.preventDefault();
        setUploading(true);
        try {
            const cover = await upload(singleFile);
            setFormData((prev) => ({
                ...prev,
                "images": cover,
            }));
            setUploading(false);

            Notifier("Image Uploaded", "success");
        } catch (err) {
            console.log(err);
            Notifier(err, "error");
        }
    };

    const handleUpdateProduct = async (e) => {
        e.preventDefault();
        setFormErrors({})
        e.preventDefault();
        setFormErrors(validateEmpty(formData));
        setIsSubmit(true);
    };

    const dispatchSignup = () => {

        if (Object.keys(formErrors).length === 0 && isSubmit) {
            dispatch(updateProduct(formData));
            setIsSubmit(false);
        }

        if (status === "succeeded") {
            Notifier(message, "success");
            //   reset();
            dispatch(reseter());
            setIsSubmit(false);
            navigate("/productlist")
        }
        if (status === "failed") {
            Notifier(message, "error");
            dispatch(reseter());
        }
    };
    referal.current = dispatchSignup;



    return (
        <div className="wrapper">

            <div className="text-center mt-4 name"> Add Utilities </div>
            <form className="p-3 mt-3">
                <div className="form-field d-flex align-items-center">
                    <input
                        type="text"
                        name="name"
                        id="namex"
                        placeholder="enter product name"
                        value={formData.name}
                        onChange={(e) => handleInput(e, setFormData)}
                    />
                </div>
                <div className="form-field d-flex align-items-center">
                    <input
                        type="text"
                        name="brand"
                        id="brand"
                        placeholder="enter product brand"
                        value={targetProduct?.brand}
                        onChange={(e) => handleInput(e, setFormData)}
                    />
                </div>
                <div className="form-field d-flex align-items-center">
                    <input
                        type="text"
                        name="price"
                        id="price"
                        placeholder="enter product price"
                        value={formData.price}
                        onChange={(e) => handleInput(e, setFormData)}
                    />
                </div>
                <div className="form-field d-flex align-items-center">
                    <select
                        name="catId"
                        id="catId"
                        onChange={(e) => handleInput(e, setFormData)}
                    >
                        <option>Select reservation Category</option>
                        {categoriesOption}
                        {/* Add more options as needed */}
                    </select>
                </div>
                <div className="form-field d-flex align-items-center">
                    <input
                        type="text"
                        name="quantity"
                        id="quantity"
                        placeholder="enter product quantity available"
                        value={formData.quantity}
                        onChange={(e) => handleInput(e, setFormData)}
                    />
                </div>
                <div className="form-field d-flex align-items-center">
                    <input
                        type="file"
                        required
                        name="images"
                        id="images"
                        onChange={(e) => setSingleFile(e.target.files[0])}
                    />

                    {!formData.images && (
                        <button className='btn' onClick={(e) => handleUpload(e)}>
                            {uploading ? "uploading" : "Upload"}
                        </button>

                    )}
                </div>
                <div className="form-field d-flex align-items-center">
                    <textarea
                        name="desc"
                        id=""
                        placeholder="brief product descriptions"
                        cols="0"
                        rows="5"
                        required
                        value={formData.desc}
                        onChange={(e) => handleInput(e, setFormData)}
                    ></textarea>
                </div>

                <button className="btn mt-3" onClick={handleUpdateProduct}>Upload</button>
            </form>
        </div>
    );
};

export default ProductUpdate;