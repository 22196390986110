import React, { useEffect } from 'react';
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import logo from "../assets/img/logo.png"
import {useLocation, useNavigate, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import decode from "jwt-decode";

// custom function 
import { setLogout, getUser } from "../slicer/Auth"
import { getUsers, reseter as resetUsers } from "../slicer/UserSlice";
import { getCategory, reseter as ResetCat } from "../slicer/Category";
import { getProducts, reseter as ResetProd } from "../slicer/Products";

const NavBar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useSelector(getUser);
    const token = user?.token || null;
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        dispatch(setLogout());
        <Navigate to="/login" state={{ from: location }} replace />;
  
      }
    }

    const handleLogout = () => {
      dispatch(setLogout());
      navigate('/login', { state: { from: location }, replace: true });
    };
  

    if (token) {
        const decodedToken = decode(token);
        if (decodedToken.exp * 1000 < new Date().getTime()) {
            dispatch(setLogout());
            <Navigate to="/" state={{ from: location }} replace />;

        }
    }
    useEffect(() => {
        dispatch(getCategory());
        dispatch(ResetCat())
        dispatch(getProducts());
        dispatch(ResetProd())
        const roler = process.env.REACT_APP_ROLER
        if (
            user?.user?.username === roler
        ) {
            dispatch(getUsers());
            dispatch(resetUsers());
        }
    }, [dispatch]);


  return (

    <Navbar bg="light" expand="lg" className='naver px-2'>
      <Navbar.Brand href="#home" className="mr-auto">
      <img
          src={logo}
          alt="Your Logo"
          width="50"
          height="30"
          className="d-inline-block align-top"
        /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
        <Nav className="ml-5">
          <Nav.Link href="/">Home</Nav.Link>

          {user?.user?.username === process.env.REACT_APP_ROLER && (
          <NavDropdown title="Menu" id="basic-nav-dropdown">
            <NavDropdown.Item href="/product_all">Product</NavDropdown.Item>
            <NavDropdown.Item href="/product_add">ProductAdd</NavDropdown.Item>
            <NavDropdown.Item href="/categories">Category</NavDropdown.Item>
            <NavDropdown.Item href="/users">Users</NavDropdown.Item>
            <NavDropdown.Item href="/productlist">Product List</NavDropdown.Item>
            <NavDropdown.Item href="/category-list">Category List</NavDropdown.Item>
          </NavDropdown>
          )}


          {user && (
            <>
              <Nav.Link className='text-bold'> <i>
                Welcome {user?.user?.username}
              </i>
              </Nav.Link>
              <Nav.Link href="/profile">Profile</Nav.Link>
              <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
            </>
          )}
          {!user && (
            <>
              <Nav.Link href="/login">Login</Nav.Link>
              <Nav.Link href="/register">Register</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    
    
  );
};

export default NavBar;
