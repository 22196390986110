import { useRef, useEffect, useState } from "react";
import logo from "../assets/img/logo.png"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleInput, validate, Notifier } from "../utills/InputHelpers";
import { register, reseter } from "../slicer/Auth";

const Register = () => {

  const { status, message } = useSelector((state) => state?.auth);
  const [signupData, setSignup] = useState({
    email: "",
    username: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const referal = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reset = () => {
    setSignup({
      email: "",
      username: "",
      password: "",
    });
  };

  useEffect(() => {
    referal.current();
  }, [formErrors, status, message, navigate, dispatch]);

  const handleSignup = (e) => {
    setFormErrors({})
    e.preventDefault();
    setFormErrors(validate(signupData));
    setIsSubmit(true);
  };

  const dispatchSignup = () => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      dispatch(register(signupData));
      setIsSubmit(false);
    }

    if (status === "succeeded") {
      Notifier(message, "success")
      reset();
      dispatch(reseter());
      setIsSubmit(false);
      navigate("/login");
    }
    if (status === "failed") {
      Notifier(message, "error")
      dispatch(reseter());
    }
  };
  referal.current = dispatchSignup;
  return (
    <div className="wrapper">
      <div className="logo">
        <img
          src={logo}
          alt="Twitter Logo"
        />
      </div>
      <div className="text-center mt-4 name">please register</div>
      <form className="p-3 mt-3" onSubmit={handleSignup}>
        <p className="text-danger">{formErrors?.username}</p>
        <div className="form-field d-flex align-items-center">
          <span className="far fa-user"></span>
          <input
            type="text"
            name="username"
            id="username"
            placeholder="username"
            value={signupData.username}
            onChange={(e) => handleInput(e, setSignup)}
          />
        </div>
        <p className="text-danger">{formErrors?.email}</p>
        <div className="form-field d-flex align-items-center">
          <span className="far fa-user"></span>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="email"
            value={signupData.email}
            onChange={(e) => handleInput(e, setSignup)}
          />
        </div>
        <p className="text-danger">{formErrors?.password}</p>
        <div className="form-field d-flex align-items-center">
          <span className="fas fa-key"></span>
          <input
            type="password"
            name="password"
            id="pwd"
            placeholder="Password"
            value={signupData.password}
            onChange={(e) => handleInput(e, setSignup)}
          />
        </div>
        {status === "loading" ? (
          ""
        ) : (
          <button className="btn mt-3">Register</button>
        )}

      </form>
      <div className="text-center fs-6">
        <a href="/login">Login</a>
      </div>
    </div>
  );
};

export default Register;
