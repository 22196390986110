import React, { Fragment } from 'react';
import pss5 from '../assets/img/pss5.png';
import ps4 from '../assets/img/ps4.jpeg';

const PlayStation = () => {
    return (
        <Fragment>
            <div className='reality'>
                <div className='reality-left'>
                    <img
                        src={pss5}
                        alt='Welcomexx'
                    />
                </div>
                <div className='reality-right'>

                    <h1>
                        Play Station
                    </h1>
                    <img
                        src={ps4}
                        alt='Welcomex'
                        className='d-inline-block align-top'
                    />
                    <p>
                        Experience nationwide accessibility with our PlayStation consoles! Simply place your order, and within the blink of an eye, your gaming delight will be on its way to you. Our swift and efficient delivery service ensures that your PlayStation console reaches you promptly, wherever you are in the country. Dive into the world of gaming with ease, knowing that your desired gaming experience is just a click away. Order now and let the gaming excitement come to you!.
                    </p>
                </div>
            </div>
        </Fragment>
    )
}

export default PlayStation
