import React, { useRef, useEffect, useState } from "react";
import logo from "../assets/img/logo.png"
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { LineWave } from "react-loader-spinner";
import {
  loaderSize,
  loaderColor,
  handleInput,
  Notifier
} from "../utills/InputHelpers";
import { reseter, confirmEmail } from "../slicer/Auth";

const Confirm = () => {

  const { user, status, message } = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({ token: "" });
  const [isSubmit, setIsSubmit] = useState(false);
  const [verified, setVerfify] = useState(false)
  const referal = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    referal.current();
  }, [status, isSubmit, message, navigate, dispatch]);

  const handleConfirm = (e) => {
    e.preventDefault();
    setIsSubmit(true);
  };

  const dispatchNewPassword = () => {
    if (isSubmit) {
      dispatch(confirmEmail({ id, token: formData.token }));
      setIsSubmit(false);

    }

    if (status === "succeeded" || user) {
      Notifier(message, "success")
      dispatch(reseter());
      setVerfify(true)
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    }
    if (status === "failed") {
      dispatch(reseter());
      // toast.error(message, { autoClose: 4000 });
      Notifier(message, "error")
      setIsSubmit(false);
    }
  };

  referal.current = dispatchNewPassword;

  return (
    <div className="wrapper">

      {!verified ? (
        <>
          <div className="logo">
            <img
              src={logo}
              alt="Logox"
            />
          </div>
          <div className="text-center mt-4 name"> Account Confirmation </div>
          <form className="p-3 mt-3" onSubmit={handleConfirm}>
            <div className="form-field d-flex align-items-center">
              <span className="far fa-user"></span>
              <input
                type="text"
                name="token"
                id="token"
                placeholder="confirmation token code"
                value={formData.password}
                onChange={(e) => handleInput(e, setFormData)}
              />
            </div>
            {status === "loading" ? (
              <LineWave
                color={loaderColor}
                height={loaderSize}
                width={loaderSize}
              />
            ) : (
              <button className="btn mt-3">confirm</button>
            )}

          </form>
        </>
      ) : (
        <div className="text-center fs-6">
          <a href="/password_reset">Login</a>
        </div>
      )}
    </div>
  );
};

export default Confirm;
