import React from 'react';
import { useSelector } from 'react-redux';

// custom function 
import { getUser } from "../slicer/Auth"

const Profile = () => {

    const { user } = useSelector(getUser);
    console.log(user, "....,,")

    return (
        <div className='container d-flex flex-row mt-5 mb-5'>

            <div className='col-md-6 p-5 border '>

                <h4 className='text-capitalize text-styled'> {user?.user?.username} Profile</h4>
                {/* {/* <img src={user?.details?.images} width={450} height={300} alt={user.details._id} /> */}

                <h5 className='text-capitalize'> {user?.user?.username}</h5>
                <p> {user?.user?.email}</p>
                <p> Status: {user?.user?.status}</p>
                {/* 
                <p> {user?.details?.city},  {user?.details?.country}</p> */}

            </div>

        </div>
    )
}

export default Profile
