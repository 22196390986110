import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import requestHandler from "../slicer/requestHandler";
import { thunkError } from "./requestHandler";


const API_URL = "auth";
export const gettate = () => {
  try {
    const user = localStorage.getItem("user");
    if (user === null) return undefined;
    return JSON.parse(user);
  } catch (error) {
    return undefined;
  }
};

const initialState = {
  user: gettate(),
  status: "idle",
  message: "",
};

// Register user
export const register = createAsyncThunk(
  "auth/register",
  async (credentials, { rejectWithValue }) => {
    try {
      return await requestHandler.axioPost(`${API_URL}/register`, credentials);
    } catch (error) {
      return rejectWithValue(thunkError(error));
    }
  }
);

// Login user
export const login = createAsyncThunk(
  "auth/login",
  async (signupData, { rejectWithValue }) => {
    try {
      return await requestHandler.axioPost(`${API_URL}/login`, signupData);
    } catch (error) {
      return rejectWithValue(thunkError(error));
    }
  }
);

// Login user
export const resetLink = createAsyncThunk(
  "auth/reset_link",
  async (resetInfo, { rejectWithValue }) => {
    try {
      return await requestHandler.axioPost(`${API_URL}/reset_link`, resetInfo);
    } catch (error) {
      return rejectWithValue(thunkError(error));
    }
  }
);
// reset_password
export const resetPassword = createAsyncThunk(
  "auth/reset",
  async (resetInfo, { rejectWithValue }) => {
    try {
      const { token, id, password } = resetInfo
      return await requestHandler.axioPost(`${API_URL}/reset_password/${id}/${token}`, { password });
    } catch (error) {
      return rejectWithValue(thunkError(error));
    }
  }
);
export const confirmEmail = createAsyncThunk(
  "user/confirmation",
  async (resetInfo, { rejectWithValue }) => {
    try {
      const { token, id } = resetInfo
      return await requestHandler.axioPost(`${API_URL}/confirm/${id}/${token}`);
    } catch (error) {
      return rejectWithValue(thunkError(error));
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, ThunkAPI) => {
  try {
    return await requestHandler.axioGet(`${API_URL}/logout`);
  } catch (error) {
    return ThunkAPI.rejectWithValue(thunkError(error));
  }
});

const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLogout: (state, action) => {
      localStorage.clear();
      state.user = null;
    },
    reseter: (state) => {
      state.status = "";
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    // register section 
    builder.addCase(register.pending, (state, _) => {
      state.status = "loading";
    });
    builder.addCase(register.rejected, (state, { payload }) => {
      state.status = "failed";
      state.message = payload;
      state.user = null;
    });
    builder.addCase(register.fulfilled, (state, { payload }) => {
      state.status = "succeeded";
      state.message =
        payload.status === 201
          ? ` ${payload?.data?.username} register successfully`
          : "Something went wrong";
    });
    // login section 
    builder.addCase(login.pending, (state, _) => {
      state.status = "loading";
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      state.status = "failed";
      state.message = payload;
      state.user = null;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {

      if (payload?.data?.flag !== "email") {
        state.status = "succeeded";
        state.message = payload?.data?.message
        localStorage.setItem("user", JSON.stringify(payload.data));
        state.user = payload.data;
      }else{
        state.status = "emailing";
        state.message = payload?.data?.message
      }
    });
    // confirm section 
    builder.addCase(confirmEmail.pending, (state, _) => {
      state.status = "loading";
    });
    builder.addCase(confirmEmail.rejected, (state, { payload }) => {
      state.status = "failed";
      state.message = payload;
      state.user = null;
    });
    builder.addCase(confirmEmail.fulfilled, (state, { payload }) => {
      state.status = "succeeded";
      console.warn(payload)
      localStorage.setItem("user", JSON.stringify(payload.data));
      state.user = payload.data;
    });
    // logout section 
    builder.addCase(logout.fulfilled, (state, _) => {
      state.user = null;
    });

    // resetPassword section 
    builder.addCase(resetPassword.pending, (state, _) => {
      state.status = "loading";
    });
    builder.addCase(resetPassword.rejected, (state, { payload }) => {
      state.status = "failed";
      state.message = payload;
    });
    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.status = "succeeded";
      state.message = payload?.data?.message;
    });
    // resetLink section 
    builder.addCase(resetLink.pending, (state, _) => {
      state.status = "loading";
    });
    builder.addCase(resetLink.rejected, (state, { payload }) => {
      state.status = "failed";
      state.message = payload;
    });
    builder.addCase(resetLink.fulfilled, (state, { payload }) => {
      state.status = "succeeded";
      state.message = payload?.data?.message;
    });

  }
});
export const getUser = (state) => state?.auth;
export const getCurrentUser = (state) => state?.auth?.user;
export const { setLogout, reseter } = authSlice.actions;
export default authSlice.reducer;