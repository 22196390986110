import './App.css';
import React, { useEffect } from "react";
import { Route, Routes,  useLocation } from "react-router-dom";
// import { useCookies } from "react-cookie";
import Footer from './components/Footer';
import Home from './screens/Home';
import { ToastContainer } from "react-toastify";
import Layout from './components/layout'
import NavBar from './components/NavBar';
import ProductListings from './screens/ProductListings';
import Login from './screens/Login';
import Register from './screens/Register';
import ProductAdd from './components/ProductAdd';
import CategoryAdd from "./components/CategoryAdd"
import Confirm from './screens/Confirm';
import ResetPassword from './screens/ResetPassword';
import NewPassword from './screens/NewPassword';
import ProductDetail from './components/ProductDetail';
import ProductList from './components/ProductList';
import ProductUpdate from './components/ProductUpdate';
import Profile from './components/Profile';
import CategoryList from './components/CatgoryList';

function App() {
  return (
    <div>
      <NavBar />
      <ToastContainer />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile/>} />
            <Route path="/password_reset" element={<ResetPassword/>}/>
            <Route path="/users_verification/:id" element={<Confirm />} />
            <Route path="/reset_now/:id" element={<NewPassword />} />
            <Route path="/product_add" element={<ProductAdd />} />
            <Route path="/products/:productId" element={<ProductDetail />} />
            <Route path="/products-update/:productId" element={<ProductUpdate />} />
            <Route path="/categories" element={<CategoryAdd />} />
            <Route path="/category-list" element={<CategoryList />} />
            <Route path="/product_all" element={<ProductListings />} />
            <Route path="/productlist" element={<ProductList />} />
          </Route>
        </Routes>

      </ScrollToTop>
      <Footer/>
    </div>
  );



}

// const ProtectUserRoute = ({ children }) => {
//   const [cookies] = useCookies();
//   const user = cookies.user;
//   if (!user) {
//     return <Navigate to="/" replace />;
//   }
//   return children;
// };

// const ProtectAdminRoute = ({ children }) => {
//   const [cookies] = useCookies();
//   const user = cookies.user;
//   if (!user) {
//     return <Navigate to="/" replace />;
//   } else if (!user.isAdmin) {
//     return <Navigate to="/" replace />;
//   }
//   return children;
// };

// const PreventMultipleLogin = ({ children }) => {
//   const [cookies] = useCookies();
//   const user = cookies.user;
//   if (user) {
//     return <Navigate to="/shop" replace />;
//   } else {
//     return children;
//   }
// };

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return children;
};

export default App;
