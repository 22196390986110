import React, { Fragment } from 'react';
import baller from '../assets/img/ball.png';
import backgroundImage from '../assets/img/bg-ps.jpg';

const BreadCumb = () => {
  const containerStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    // height: '100vh', // Set the desired height
    // // Add other styles as needed
  };
  return (
    <Fragment>
      <div style={containerStyle} className='bread'>
        <div className='bread-left'>
          <h1> Purchase your Pro Evolution Soccer utils
            <br/>
            <span> Now</span>
          </h1>
          <img
            src={baller}
            alt='Welcomex'
            className='d-inline-block align-top'
          />
        </div>
        <div className='bread-right'>
          {/* <img
            src={landy}
            alt='Welcomex'
            className='d-inline-block align-top'
          /> */}
        </div>
      </div>
    </Fragment>
  );
};

export default BreadCumb;
