import React from 'react'
import ProductAdList from '../components/ProductAdList'

const ProductListings = () => {
  return (
    <div>
      <ProductAdList/>
    </div>
  )
}

export default ProductListings
