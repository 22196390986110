import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const ProductDetail = () => {

    const { productId } = useParams();

    // const product = useSelector(getProductById(productId));
    const products = useSelector((state) => state.products?.products);
    const productArray = Array.from(products).filter((product) => product._id === productId);
    const product = productArray[0];

    const text = `
    Hi, i want to order for the product: ${product?.name} for the price: ${product?.price} with the description: ${product?.desc}
    `;

    const handleWhatsAppClick = (e) => {
        const whatsappUrl = `https://api.whatsapp.com/send?phone=2348107087808&text=${encodeURI(text)}`;
        window.open (whatsappUrl); // Redirect to WhatsApp URL
        // window.location.href = whatsappUrl; // Redirect to WhatsApp URL
    };

    return (
        <div className="container mt-5 mb-5 mbv5">
            <div className="d-flex justify-content-center row">
                <div className="col-md-10">
                    <div className="row p-2 bg-white border rounded">
                        <div className="col-md-3 mt-1">
                            <img
                                className="img-fluid img-responsive rounded product-image"
                                src={product?.images}
                                alt="Product"
                            />
                        </div>
                        <div className="col-md-6 mt-1">
                            <h5> {product?.name}</h5>
                            <div className="mt-1 mb-1 spec-1">
                                <span> Quantity : </span>
                                <span className="dot"></span>
                                <span> {product?.quantity} </span>
                            </div>
                            <div className="mt-1 mb-1 spec-1">
                                <span>Category: </span>
                                <span className="dot"></span>
                                <span> {product?.catId?.name}</span>
                            </div>
                            <p className="text-justify text-truncate para mb-0">
                                {product?.desc}<br />
                            </p>
                        </div>
                        <div className="align-items-center align-content-center col-md-3 border-left mt-1">
                            <div className="d-flex flex-row align-items-center">
                                <h4 className="mr-1">$ {product?.price}</h4>
                            </div>
                            <h6 className="text-success">Actions</h6>
                            <div className="d-flex flex-column mt-4">
                                {/* <a
                                    href={`https://api.whatsapp.com/send?phone=2348068659608&text=${text}`}
                                > */}
                                    <button className="btn btn-outline-success"  onClick={()=>handleWhatsAppClick()} type="submit">whatsapp</button>
                                {/* </a> */}
                            </div>
                        </div>
                    </div>
                    {/* Additional product entries go here */}
                </div>
            </div>
        </div>

    )
}

export default ProductDetail
