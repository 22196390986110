import React, { Fragment } from 'react';
import landy from '../assets/img/vision-psxx.jpg';

const Reality = () => {
  return (
    <Fragment>
      <div className='reality'>
        <div className='reality-left'>
          <img
            src={landy}
            alt='Welcomexx'
          />
        </div>
        <div className='reality-right'>
          <h1>
            Virtual World
          </h1>

          <p>The world of virtual reality can now enhance the usage of PES

            Virtual Reality (VR) games provide an immersive gaming experience by utilizing virtual reality headsets and other devices to simulate a three-dimensional environment.
            Players can interact with the virtual world, explore different scenarios, and often have a more realistic and engaging gaming experience compared to traditional video games.
          </p>
        </div>
      </div>
    </Fragment>
  )
}

export default Reality
