import React, { Fragment } from 'react';
import landy from '../assets/img/play.jpg';

const Play = () => {
  return (
    <Fragment>
    <div className='reality mt-1'>
      <div className='reality-left'>
        <img
          src={landy}
          alt='Welcomexx'
          />
      </div>
      <div className='reality-right bg-white'>
          <h1> 
            Keep Playing
          </h1>

          <p>
Explore a world of endless entertainment with our curated selection of PlayStation utilities, designed to elevate your gaming experience. Unlock a realm of immersive gameplay, cutting-edge accessories, and essential tools that enhance every moment of your gaming journey. Elevate your passion for gaming with the latest and greatest PlayStation utilities, ensuring that the fun never stops.
 Discover a range of must-have accessories and peripherals that complement your PlayStation console, making each gaming session a thrilling adventure. Level up your gaming setup and indulge in a seamless, high-performance experience with our premium selection of PlayStation utilities. Your joy in gaming is our priority, and we are here to supply you with everything you need to maximize the enjoyment of your gaming escapades.</p>
      </div>
    </div>
  </Fragment>
  )
}

export default Play
